import React, { useState } from 'react';
import './Floor.css';
import FloorPopUp from './../FloorPopUp/FloorPopup'; // Ensure the path to FloorPopUp is correct

const Floor = ({ floorName, floorPlanImage }) => {
  const [highlightedApartment, setHighlightedApartment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [floorNumber] = useState(14); // Example floor number
  const [apartmentNumber, setApartmentNumber] = useState(null);

  const apartments = {
    1: { points: "1015,100 1038,100 1038,0 1358,0 1358,580 1528,580 1528,993 1015,993", area: "134.7 კვ.მ." },
    2: { points: "1522,0 1858,0 1858,100 1878,100 1878,993 1530,993", area: "111,8 კვ.მ" },
  };

  const calculateCentroid = (points) => {
    const pts = points.split(' ').map(p => p.split(',').map(Number));
    const n = pts.length;
    let cx = 0, cy = 0, area = 0;

    for (let i = 0; i < n; i++) {
      const [x1, y1] = pts[i];
      const [x2, y2] = pts[(i + 1) % n];
      const a = x1 * y2 - x2 * y1;
      area += a;
      cx += (x1 + x2) * a;
      cy += (y1 + y2) * a;
    }

    area *= 0.5;
    cx /= (6 * area);
    cy /= (6 * area);

    return { cx, cy };
  };

  const handleMouseEnter = (apartment) => {
    setHighlightedApartment(apartment);
  };

  const handleMouseLeave = () => {
    setHighlightedApartment(null);
  };

  const handleClick = (apartment) => {
    setSelectedApartment(apartments[apartment]);
    setApartmentNumber(apartment); // Set the apartment number
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedApartment(null);
    setApartmentNumber(null); // Reset the apartment number
  };

  return (
    <div className="floor-container">
      <h1 className="floor-title">{floorName}</h1>
      <div className="floor-plan">
        <img src={floorPlanImage} alt={`${floorName} Plan`} className="floor-image" />
        <svg className="overlay" viewBox="0 0 1000 1000" preserveAspectRatio="xMinYMin meet">
          {Object.entries(apartments).map(([key, { points, area }]) => {
            const { cx, cy } = calculateCentroid(points);
            return (
              <g key={key} onMouseEnter={() => handleMouseEnter(key)} onMouseLeave={handleMouseLeave} onClick={() => handleClick(key)}>
                <polygon
                  points={points}
                  fill={highlightedApartment === key ? 'rgba(0, 255, 255, 0.6)' : 'transparent'}
                />
                <text
                  x={cx}
                  y={cy}
                  className="apartment-info-text"
                  style={{ display: highlightedApartment === key ? 'block' : 'none' }}
                >
                  {area}
                </text>
              </g>
            );
          })}
        </svg>
      </div>
      {showPopup && <FloorPopUp apartment={selectedApartment} floorNumber={floorName} apartmentNumber={apartmentNumber} onClose={closePopup} />}
    </div>
  );
};

export default Floor;
