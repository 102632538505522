import React, { useRef } from 'react';
import './FloorPopup.css';
import floorPopUpImg from './../../assets/137.7.png';
import emailjs from 'emailjs-com';

const FloorPopUp = ({ apartment, floorNumber, apartmentNumber, onClose }) => {
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent actual form submission

    emailjs.sendForm('service_t8a0m0s', 'template_m1bh2km', formRef.current, 'zEDcxb6qqtlqy1uky')
      .then(
        () => {
          console.log('SUCCESS!');
          onClose(); // Close popup on successful sending
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  if (!apartment) return null;

  return (
    <div className="FloorPopUp-overlay">
      <div className="FloorPopUp-content">
        <span className="FloorPopUp-closeButton" onClick={onClose}>&times;</span>
        <h2 className="FloorPopUp-title">ბინის დეტალები</h2>
        <div className="FloorPopUp-body">
          <img src={floorPopUpImg} alt="Apartment" className="FloorPopUp-image" />
          <div className="FloorPopUp-info">
            {/* <h3 className="FloorPopUp-projectName">{`პროექტი: საბურთალო`}</h3> */}
            <p className="FloorPopUp-area">{`${floorNumber} / ფართი ${apartment.area}`}</p>
            <h2 className="FloorPopUp-title">ზარის მოთხოვნა</h2>
            <form className="FloorPopUp-form" ref={formRef} onSubmit={sendEmail}>
              <input type="text" name="user_name" placeholder="სახელი" className="FloorPopUp-input" />
              <input type="email" name="user_mail" placeholder="ელ.ფოსტა" className="FloorPopUp-input" />
              <input type="tel" name="user_number" placeholder="ტელეფონი" className="FloorPopUp-input" />
              <textarea name="user_message" placeholder="შეტყობინება" className="FloorPopUp-textarea"></textarea>
              <button type="submit" className="FloorPopUp-submitButton">გაგზავნა</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorPopUp;
