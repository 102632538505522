// ContactUs.js

import React from 'react';
import './ContactUs.css';
import ContactUsBlock from '../../components/ContactUsBlock/ContactUsBlock';
import ContactSection from '../../components/ContactSection/ContactSection';


function ContactUs() {
  return (
    <div className="ContactUs">
      <ContactUsBlock/>
      <ContactSection/>
    </div>
  );
}

export default ContactUs;
