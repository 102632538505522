import React, { useRef, useState } from 'react';
import './ContactSection.css';
import emailjs from '@emailjs/browser';
import { ReactComponent as LocationIcon } from './../../assets/marker_CU.svg';
import { ReactComponent as PhoneIcon } from './../../assets/phone_CU.svg';
import { ReactComponent as EmailIcon } from './../../assets/mail_CU.svg';
import { ReactComponent as FacebookIcon } from './../../assets/Facebook_CU.svg';
import { ReactComponent as InstagramIcon } from './../../assets/Insta_CU.svg';
import { ReactComponent as LinkedInIcon } from './../../assets/Linkedin_CU.svg';
import { ReactComponent as YouTubeIcon } from './../../assets/YT_CU.svg';
import { ReactComponent as TikTokIcon } from './../../assets/TikTok_CU.svg';

const ContactSection = () => {
  const formRef = useRef();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault(); // Prevent actual form submission

    emailjs.sendForm('service_t8a0m0s', 'template_m1bh2km', formRef.current, {
      publicKey: 'zEDcxb6qqtlqy1uky',
    })
    .then(
      () => {
        console.log('SUCCESS!');
        setIsFormVisible(false); // Close form on successful sending
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
  };

  return (
    <div className="contact-section-wrapper">
      <div className="contact-section">
        <div className="map-info-container">
          <div className="map-container">
            <iframe
              title="Our Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d744.3314008267571!2d44.767386273122995!3d41.73506930000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404472ef3e20cb2d%3A0x60c5673bb40676b7!2zMTkg4YOU4YOa4YOY4YOh4YOQ4YOR4YOU4YOTIOGDqeGDlOGDoOGDpeGDlOGDluGDmOGDqOGDleGDmOGDmuGDmOGDoSDhg6Xhg6Phg6nhg5AsIOGDl-GDkeGDmOGDmuGDmOGDoeGDmA!5e0!3m2!1ska!2sge!4v1718178528832!5m2!1ska!2sge"
              width="456"
              height="456"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="contact-info">
            <h1 className='contact-info-h1'>საკონტაქტო ინფორმაცია</h1>
            <div className="info-item">
              <a href="https://maps.app.goo.gl/CnA49NkuKJurdJrf6" className="info-item" target="_blank" rel="noopener noreferrer">
                <LocationIcon className="contact-icon" />
                <span className='info-item-text'>ე. ჩერქეზიშვილის #19</span>
              </a>
            </div>
            <div className="info-item">
              <a href="tel:+557440464" className="info-item">
                <PhoneIcon className="contact-icon" />
                <span className='info-item-text'>557 44 04 64</span>
              </a>
            </div>
            <div className="info-item">
              <a href="tel:+557440464" className="info-item">
                <PhoneIcon className="contact-icon" />
                <span className='info-item-text'>557 44 04 24</span>
              </a>
            </div>
            <div className="info-item">        
              <a href="mailto:contact@solidi.ge" className="info-item">
                <EmailIcon className="contact-icon" />
                <span className='info-item-text'>contact@solidi.ge</span>
              </a>
            </div>
            <div className="info-item">        
              <a href="mailto:marketing@solidi.ge" className="info-item">
                <EmailIcon className="contact-icon" />
                <span className='info-item-text'>marketing@solidi.ge</span>
              </a>
            </div>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookIcon className="contactus-social-icon facebook_cu" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramIcon className="contactus-social-icon instagram_cu" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon className="contactus-social-icon linkedin_cu" />
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon className="contactus-social-icon youtube_cu" />
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                <TikTokIcon className="contactus-social-icon tiktok_cu" />
              </a>
            </div>
          </div>
        </div>
        <form className="contact-form" ref={formRef} onSubmit={sendEmail}>
          <input type="text" name="user_name" placeholder="სახელი" />
          <input type="email" name="user_mail" placeholder="ელ.ფოსტა" />
          <input type="tel" name="user_number" placeholder="ტელეფონი" />
          <textarea name="user_message" placeholder="შეტყობინება"></textarea>
          <button type="submit">გაგზავნა</button>
        </form>
      </div>
    </div>
  );
};

export default ContactSection;
