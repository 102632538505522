// AboutUs.js

import React from 'react';
import './AboutUs.css';
import AboutUsBlock from '../../components/AboutUsBlock/AboutUsBlock';
import AboutCompany from '../../components/AboutCompany/AboutCompany';


function AboutUs() {
  return (
    <div className="AboutUs">
      <AboutUsBlock/>
      <AboutCompany 
        title="სოლიდ დეველოპმენტი"
        text="
        წარმოადგენს დეველოპერულ კომპანიას, რომელიც აშენებს პრემიუმ კლასის საცხოვრებელ პროექტებს
        ჩვენი პროექტები გამოირჩევა თანამედროვე არქიტექტურით, ოპტიმალური გეგმარებით, განსხვავებული დიზაინით და საუკეთესო 
        ადგილმდებაროებით, როგორც საცხოვრებლად ისე საინვესტიციოდ სოლიდ დეველოპმენტის მთავარი მიზანია 
        კომფორტული, ხარისხიანი და მომხმარებელზე მორგებული საცხოვრებელი კომპლექსების შექმნა სოლიდ დეველოპმენტის 
        პროექტები შენდება ევროპული სტანდარტების სრული დაცვით, რაც თქვენი ბინის მაქსიმალურ მდგრადობას, 
        ენერგო-ეფექტურობასა და უსაფრთოებას უზრუნვლეყოფს, ბინები ბარდება მწვანე კარკასის კონდიციით.
        ჩვენ ვზრუნავთ კომფორტული ცხოვრების თანამედროვე და პრემიუმ ხარისხის პროდუქტის შექმნაზე"
        buttonText="გაიგე მეტი"
      />
    </div>
  );
}

export default AboutUs;
