/* Footer.js */
import React from 'react';
import './Footer.css';
import { ReactComponent as Logo } from './../../assets/logo white 1.svg'; // Update the SVG import if necessary
import { ReactComponent as LocationIcon } from './../../assets/marker.svg';
import { ReactComponent as TelephoneIcon } from './../../assets/phone-call.svg';
import { ReactComponent as MailIcon } from './../../assets/envelope.svg';
import { ReactComponent as FacebookIcon } from './../../assets/FB.svg';
import { ReactComponent as InstagramIcon } from './../../assets/Insta.svg';
import { ReactComponent as LinkedInIcon } from './../../assets/Linkedin.svg';
import { ReactComponent as YouTubeIcon } from './../../assets/YT.svg';
import { ReactComponent as TikTokIcon } from './../../assets/TikTok.svg';

const Footer = () => (
  <footer className="footer">
    <div className="footer-column">
      <Logo className="footer-logo" />
      <div className="footer-socials">
        <a href="https://www.facebook.com/SolidDevelopmenti" target="_blank" rel="noopener noreferrer">
          <FacebookIcon className="footer-social-icon facebook" />
        </a>
        <a href="https://www.instagram.com/solid.development" target="_blank" rel="noopener noreferrer">
          <InstagramIcon className="footer-social-icon instagram" />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon className="footer-social-icon linkedin" />
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <YouTubeIcon className="footer-social-icon youtube" />
        </a>
        <a href="https://www.tiktok.com/@solid_development" target="_blank" rel="noopener noreferrer">
          <TikTokIcon className="footer-social-icon tiktok" />
        </a>
      </div>
    </div>
    <div className="footer-column">
      <h4 className="footer-heading">ბმულები</h4>
      <a href="/" className="footer-link">მთავარი</a>
      <a href="/about" className="footer-link">ჩვენს შესახებ</a>
      <a href="/projects" className="footer-link">პროექტები</a>
      <a href="/gallery" className="footer-link">გალერეა</a>
      <a href="/contact" className="footer-link">კონტაქტი</a>
    </div>
    <div className="footer-column">
      <h4 className="footer-heading">საკონტაქტო ინფორმაცია</h4>
      <div className="footer-contact-info">
        <a href="https://maps.app.goo.gl/CnA49NkuKJurdJrf6" target="_blank" rel="noopener noreferrer">
          <LocationIcon className="footer-icon" />
          <span className='footer-text'>ე. ჩერქეზიშვილის #19</span>
        </a>      
      </div>
      <div className="footer-contact-info">
        <a href="tel:557440464">
          <TelephoneIcon className="footer-icon" />
          <span className="footer-text">557 44 04 64</span>
        </a>     
      </div>
      <div className="footer-contact-info">
        <a href="tel:557440424">
          <TelephoneIcon className="footer-icon" />
          <span className="footer-text">557 44 04 24</span>
        </a>
      </div>
      <div className="footer-contact-info">
        <a href="mailto:contact@solidi.ge">
          <MailIcon className="footer-icon" />
          <span className="footer-text">contact@solidi.ge</span>
        </a> 
      </div>
      <div className="footer-contact-info">
        <a href="mailto:marketing@solidi.ge">
          <MailIcon className="footer-icon" />
          <span className="footer-text">marketing@solidi.ge</span>
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;