// Home.js

import React from 'react';
import './Home.css';
import Hero from './../../components/Hero/Hero';
import Block1 from './../../components/Blocks/Block1/Block1';
import CardsContainer from './../../components/Cards/Cards';
import CenteredBlock from './../../components/Blocks/CenteredBlock/CenteredBlock';
import CallComponent from './../../components/CallComponent/CallComponent';

function Home() {
  return (
    <div className="Home">
      <CallComponent />
      <Hero/>
      <Block1 
        title="ჩვენს შესახებ"
        text="სოლიდ დეველოპმენტი წარმოადგენს დეველოპერულ კომპანიას, რომელიც აშენებს პრემიუმ კლასის საცხოვრებელ პროექტებს
        ჩვენი პროექტები გამოირჩევა თანამედროვე არქიტექტურით, ოპტიმალური გეგმარებით, განსხვავებული დიზაინით და საუკეთესო 
        ადგილმდებაროებით, როგორც საცხოვრებლად ისე საინვესტიციოდ სოლიდ დეველოპმენტის მთავარი მიზანია 
        კომფორტული, ხარისხიანი და მომხმარებელზე მორგებული საცხოვრებელი კომპლექსების შექმნა სოლიდ დეველოპმენტის 
        პროექტები შენდება ევროპული სტანდარტების სრული დაცვით, რაც თქვენი ბინის მაქსიმალურ მდგრადობას, 
        ენერგო-ეფექტურობასა და უსაფრთოებას უზრუნვლეყოფს, ბინები ბარდება მწვანე კარკასის კონდიციით.
        ჩვენ ვზრუნავთ კომფორტული ცხოვრების თანამედროვე და პრემიუმ ხარისხის პროდუქტის შექმნაზე"
        buttonText="გაიგე მეტი"
      />
      {/* <CenteredBlock
        title="მოდი ვიმუშაოთ ერთად"
        text=" Ipsum-ის პასაჟიდან და კლასიკურ ლიტერატურაში ამ სიტყვის ციტატების გავლის შემდეგ აღმოაჩინა უდავო წყარო. 
        Lorem Ipsum მომდინარეობს ციცერ Ipsum-იMal"
        buttonText="გაიგე მეტი"
      /> */}
    </div>
  );
}

export default Home;
