import React from 'react'
import BuildingWithFloors from '../../components/BuildingWithFloors/BuildingWithFloors'
import BuildingBlock from '../../components/BuildingBlock/BuildingBlock'

function Building() {
  return (
    <div className=''>
        <BuildingBlock/>
        <BuildingWithFloors/>
    </div>
  )
}

export default Building