import React from 'react';
import './AboutCompany.css'; // Make sure this path is correct
import AboutCompanyImg from './../../assets/6.jpg'; // Update with the correct path to your image

const AboutCompany = ({ title, text, buttonText }) => (
  <div className="AboutCompany">
    <div className="AboutCompany-image-container">
      <img src={AboutCompanyImg} alt="Building" className="AboutCompany-image" />
    </div>
    <div className="AboutCompany-content">
      <h1 className="AboutCompany-title">{title}</h1>
      <p className="AboutCompany-text">{text}</p>
      <button className="AboutCompany-button">{buttonText}</button>
    </div>
  </div>
);

export default AboutCompany;
