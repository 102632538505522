import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Hero.css'

function Hero() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/projects');
  };

  return (
    <div className='hero container'>
        <div className="hero-text">
            <h1>იპოვე შენი ოცნების ბინა</h1>
            <button className="btn" onClick={handleButtonClick}>შეარჩიე ბინა</button>
        </div>
    </div>
  )
}

export default Hero