import React, { useRef } from 'react';
import "./PopUp.css";
import emailjs from '@emailjs/browser';

const Popup = ({ closePopup }) => {
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent actual form submission

    emailjs.sendForm('service_t8a0m0s', 'template_m1bh2km', formRef.current, {
      publicKey: 'zEDcxb6qqtlqy1uky',
    })
    .then(
      () => {
        console.log('SUCCESS!');
        closePopup(); // Close the popup on successful email sending
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
  };

  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup" onClick={e => e.stopPropagation()}>
        <div className="popup-header">
          <span role="img" aria-label="Phone" className="popup-icon">📞</span>
          <h2>ზარის მოთხოვნა</h2>
          <button className="close-popup-btn" onClick={closePopup}>×</button>
        </div>
        <p className="popup-instructions">შეავსეთ მონაცემები და თავად დაგიკავშირდებით უახლოეს დროში</p>
        <form className="popup-form" ref={formRef} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">სახელი</label>
            <input id="name" name="user_name" type="text" placeholder="სახელი" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">ტელეფონის ნომერი</label>
            <input id="phone" name="user_number" type="tel" placeholder="ნომერი" required />
          </div>
          <button type="submit" className="submit-btn">გაგზავნა</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
