import React, { useState, useRef } from 'react';
import './CallComponent.css';
import { ReactComponent as PhoneIcon } from './../../assets/phone-call_white.svg';
import emailjs from '@emailjs/browser';

const CallComponent = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent actual form submission

    emailjs.sendForm('service_t8a0m0s', 'template_m1bh2km', formRef.current, {
      publicKey: 'zEDcxb6qqtlqy1uky',
    })
    .then(
      () => {
        console.log('SUCCESS!');
        setIsFormVisible(false); // Close form on successful sending
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
  };

  const toggleForm = () => {
    if (isFormVisible) {
      // Assume the form is being submitted when toggling from a visible state
      formRef.current && formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    } else {
      setIsFormVisible(true);
    }
  };

  return (
    <div className="call-container">
      <div className={`call-button ${isFormVisible ? 'active' : ''}`} onClick={toggleForm}>
        <PhoneIcon className="phone-icon" />
        <span className="call-text">შეუკვეთე ზარი</span>
      </div>

      {isFormVisible && (
        <div className="call-form">
          <button className="close-btn" onClick={() => setIsFormVisible(false)}>X</button>
          <h2 className="form-title">შეუკვეთე ზარი</h2>
          <form ref={formRef} onSubmit={sendEmail}>
            <input type="text" name="user_name" placeholder="სახელი" required />
            <input type="tel" name="user_number" placeholder="ნომერი" required />
            <button type="submit" className="submit-btn">გაგზავნა</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CallComponent;
