import React from 'react'
import './BuildingBlick.css'
function BuildingBlock() {
  return (
    <div className='BuildingBlock container'>
        <div className="BuildingBlock-text">
            <h1>პროექტები</h1>
        </div>
    </div>
  )
}

export default BuildingBlock
