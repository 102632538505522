import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BuildingWithFloors.css';
import buildingImage from './../../assets/sadasda.jpg'; // Path to your building image

const floorsData = [
  { id: 'floor1', label: 'სართული 1', points: "210,970 1130,970 1130,780 210,780", labelCoords: { x: 665, y: 880 } },
  { id: 'floor2', label: 'სართული 2', points: "210,780 1130,780 1130,600 210,600", labelCoords: { x: 665, y: 685 } },
  { id: 'floor3', label: 'სართული 3', points: "210,600 1130,600 1130,420 210,420", labelCoords: { x: 665, y: 520 } },
  // ... Add more floors with their respective points
];

const BuildingWithFloors = () => {
  const [highlightedFloor, setHighlightedFloor] = useState(null);

  const handleMouseEnter = (floorId) => {
    setHighlightedFloor(floorId);
  };

  const handleMouseLeave = () => {
    setHighlightedFloor(null);
  };

  return (
    <div className="building-container">
      <div className="image-container">
        <h1 className='image-container-h1'>თბილისი, ჩერქეზიშვილის N:19</h1>
        <img src={buildingImage} alt="Building" className="building-image" />
        <svg className="overlay" viewBox="0 0 1330 800" preserveAspectRatio="xMinYMin meet">
          {floorsData.map(floor => (
            <Link
              key={floor.id}
              to={floor.id}
              className="floor"
              onMouseEnter={() => handleMouseEnter(floor.id)}
              onMouseLeave={handleMouseLeave}
            >
              <polygon
                points={floor.points}
                fill={highlightedFloor === floor.id ? 'rgba(0, 255, 255, 0.3)' : 'transparent'}
                stroke={highlightedFloor === floor.id ? 'white' : 'transparent'}
                strokeWidth="2"
              />
              <text
                x={floor.labelCoords.x}
                y={floor.labelCoords.y}
                className={`floor-label ${highlightedFloor === floor.id ? 'visible' : 'hidden'}`}
              >
                {floor.label}
              </text>
            </Link>
          ))}
        </svg>
      </div>
    </div>
  );
};

export default BuildingWithFloors;
