// Header.js
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { NavLink, useLocation } from "react-router-dom";
import Popup from './../../components/PopUp/Popup';
import logo from './../../assets/logo 4.svg'; // Update the path if necessary
import "./Header.css";
import { FaBars } from "react-icons/fa";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    setIsSmallScreen(mediaQuery.matches);
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <header className="Header">
      <NavLink to="/" className="LogoLink">
        <img src={logo} alt="Logo" className="Logo" />
      </NavLink>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <NavLink to="/" exact className="NavLink" activeClassName="ActiveLink" onClick={toggleNav}>მთავარი</NavLink>
          <NavLink to="/about" className="NavLink" activeClassName="ActiveLink" onClick={toggleNav}>ჩვენს შესახებ</NavLink>
          <NavLink to="/projects" className="NavLink" activeClassName="ActiveLink" onClick={toggleNav}>პროექტები</NavLink>
          {/* <NavLink to="/gallery" className="NavLink" activeClassName="ActiveLink" onClick={toggleNav}>გალერეა</NavLink> */}
          <NavLink to="/contact" className="NavLink" activeClassName="ActiveLink" onClick={toggleNav}>კონტაქტი</NavLink>
          <button className="nav-button" onClick={togglePopup}>დაგვიკავშირდით</button>
        </nav>
      </CSSTransition>
      <FaBars onClick={toggleNav} className="Burger" />
      {isPopupOpen && <Popup closePopup={togglePopup} />}
    </header>
  );
}
