import React from 'react'
import './AboutUsBlock.css'
function AboutUsBlock() {
  return (
    <div className='AboutUsBlock container'>
        <div className="AboutUsBlock-text">
            <h1>ჩვენს შესახებ</h1>
        </div>
    </div>
  )
}

export default AboutUsBlock
