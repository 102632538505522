// Gallery.js

import React from 'react';
import './Gallery.css';
import GallerySlider from '../../components/GallerySlider/GallerySlider';
import GalleryBlock from '../../components/GalleryBlock/GalleryBlock';
import CarouselBlock from '../../components/CarouselBlock/CarouselBlock';


function Gallery() {
  return (
    <div className="Gallery">
      <GalleryBlock/>
      <CarouselBlock/>
      {/* <GallerySlider/> */}
    </div>
  );
}

export default Gallery;
