// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Header from './pages/Header/Header';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Gallery from './pages/Gallery/Gallery';
import Building from './pages/Building/Building';
import Floor from './components/Floor/Floor';

import floorImage1 from './assets/Floor.png';  // Assuming these paths are correct
import floorImage2 from './assets/Floor.png';
import floorImage3 from './assets/Floor.png';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/gallery" element={<Gallery />} /> */}
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/projects" element={<Building />} />
          <Route path="/projects/floor1" element={<Floor floorName="სართული 1" floorPlanImage={floorImage1} />} />
          <Route path="/projects/floor2" element={<Floor floorName="სართული 2" floorPlanImage={floorImage2} />} />
          <Route path="/projects/floor3" element={<Floor floorName="სართული 3" floorPlanImage={floorImage3} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
