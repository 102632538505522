import React from 'react'
import './ContactUsBlock.css'
function ContactUsBlock() {
  return (
    <div className='ContactUsBlock container'>
        <div className="ContactUsBlock-text">
            <h1>კონტაქტი</h1>
        </div>
    </div>
  )
}

export default ContactUsBlock
