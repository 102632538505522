import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Block1.css'; // Make sure this path is correct
import block1Img from './../../../assets/5_up.jpg'; // Update with the correct path to your image

const Block1 = ({ title, text, buttonText }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/about');
  };

  return (
    <div className="block1">
      <div className="block1-image-container">
        <img src={block1Img} alt="Building" className="block1-image" />
      </div>
      <div className="block1-content">
        <h1 className="block1-title">{title}</h1>
        <p className="block1-text">{text}</p>
        <button className="block1-button" onClick={handleButtonClick}>{buttonText}</button>
      </div>
    </div>
  );
};

export default Block1;
